/* eslint-disable @next/next/no-img-element */
// components
import { Container } from '@/components/Grid';

// assets
import ShipperGuideLogo from '@/public/images/logos/logo_shipper-guide-powered-by-loadsmart.svg';

// styles
import {
  SidePanelsWrapper,
  SectionTitle,
  SectionIntro,
  SectionPanel,
  LogoWrapper,
  Title,
  Subtitle,
  Content,
  Link,
  Picture,
  Wrapper,
} from './SidePanels.styles';

export function SidePanels({ content }) {
  return (
    <SidePanelsWrapper>
      <Container>
        {content.title && <SectionTitle>{content.title}</SectionTitle>}
        {content.subtitle && <SectionIntro>{content.subtitle}</SectionIntro>}
        {content.features &&
          content.features.map((item, key) => {
            const panelKey = `sidepanel-${key}`;
            return (
              <SectionPanel key={panelKey} direction={key % 2 ? `reverse` : ``}>
                <Picture direction={key % 2 ? `reverse` : ``}>
                  <source
                    srcSet={`${item.image.path.xxlarge}`}
                    media="(min-width: 1920px)"
                  />
                  <source
                    srcSet={`${item.image.path.large}`}
                    media="(min-width: 1024px)"
                  />
                  <source
                    srcSet={`${item.image.path.medium}`}
                    media="(min-width: 768px)"
                  />
                  <img
                    src={`${item.image.path.small}`}
                    alt={item.image.alt}
                    width="677"
                    height="520"
                  />
                </Picture>
                <Wrapper direction={key % 2 ? `reverse` : ``}>
                  {item.logo && <LogoWrapper>{item.logo}</LogoWrapper>}
                  {item.title && <Title>{item.title}</Title>}
                  {item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
                  <Content>{item.content}</Content>
                  <Link
                    href={item.link.url}
                    target={item.link.external ? '_blank' : null}
                    rel={item.link.external ? 'noreferrer noopener' : null}
                  >
                    {item.link.label}
                  </Link>
                </Wrapper>
              </SectionPanel>
            );
          })}
      </Container>
    </SidePanelsWrapper>
  );
}
